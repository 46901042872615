<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          :src="getCategory.logoUrl"
          :variant="`light-primary`"
          size="90px"
          rounded
        />
      </template>
      <div class="d-flex mb-1 align-items-center">
        <h4 class="mb-0">
          {{ getCategory.titleRu }}
        </h4>
      </div>
      <div class="d-flex flex-wrap">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @change="handleFileUpload($event)"
            accept="image/png, image/jpeg, image/tiff"
          />
          <span class="d-none d-sm-inline">Загрузить</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
      <div>
        <small class="text-danger" v-if="imageRequired === 1"
          >Поле Лого поддерживает только файлы в формате .jpg, .png,
          .tiff</small
        >
      </div>
      <span class="sm-size"
        >(Поддерживаются файлы в формате .jpg, .png, .tiff)
      </span>
    </b-media>

    <validation-observer ref="simpleRules">
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <!-- Field: Full Name -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Название Ru"
              rules="required"
            >
              <b-form-group label="Название Ru" label-for="full-name-ru">
                <b-form-input
                  id="full-name-ru"
                  :state="errors.length > 0 ? false : null"
                  v-model="getCategory.titleRu"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Full Name -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Название Uz"
              rules="required"
            >
              <b-form-group label="Название Uz" label-for="full-name-uz">
                <b-form-input
                  id="full-name-uz"
                  :state="errors.length > 0 ? false : null"
                  v-model="getCategory.titleUz"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Full Name -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Название En"
              rules="required"
            >
              <b-form-group label="Название En" label-for="full-name-en">
                <b-form-input
                  id="full-name-en"
                  :state="errors.length > 0 ? false : null"
                  v-model="getCategory.titleEn"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12">
            <!-- Action Buttons -->
            <b-button
              @click.prevent="handleSubmit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import axiosIns from "@/libs/axios.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";

export default {
  components: {
    ToastificationContent,
    BBadge,
    BFormTextarea,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {},
  computed: {
    ...mapGetters(["getCategory"]),
    statusVariant() {
      const statusVariants = {
        /* eslint-disable key-spacing */
        ACTIVE: {
          color: "light-success",
          text: "Активный",
        },
        DELETED: {
          color: "light-danger",
          text: "Удаленный",
        },
        BLOCKED: {
          color: "light-warning",
          text: "Заблокированный",
        },
        SUSPENDED: {
          color: "light-secondary",
          text: "Приостановленный",
        },
        /* eslint-enable key-spacing */
      };

      return (status) => statusVariants[status];
    },
  },
  data() {
    return {
      required,
      email,
      password,
      confirmed,

      file: null,
      imageRequired: 0,
      fileUrl: "",
      categories: null,
      logo: null,
      statusOptions: ["ACTIVE", "DELETED", "BLOCKED", "SUSPENDED"],
    };
  },
  methods: {
    async handleFileUpload(event) {
      this.file = event.target.files[0];
      if (
        this.file.type != "image/jpg" &&
        this.file.type != "image/jpeg" &&
        this.file.type != "image/png" &&
        this.file.type != "image/tiff"
      ) {
        this.file = "";
        this.imageRequired = 1;
        return false;
      }
      this.imageRequired = 2;
      const formData = new FormData();
      formData.append("logo", this.file);
      const response = await axiosIns.post("image/upload/category", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.getCategory.logoUrl = response.data.data.imageUrl;
    },
    changeCategoryList() {},
    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success && this.imageRequired === 2) {
          axiosIns
            .put("vendor-category/edit", {
              id: this.getCategory.id,
              titleRu: this.getCategory.titleRu,
              titleUz: this.getCategory.titleUz,
              titleEn: this.getCategory.titleEn,
              logoUrl: this.getCategory.logoUrl,
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Категория была успешно изменена!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.$router.push({ name: "categories" });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
